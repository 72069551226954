import clonedeep from "lodash.clonedeep";
import get from "lodash.get";
import set from "lodash.set";
import snakecase from "lodash.snakecase";
import {
  formatContentConfig,
  removeWWW
} from "~/components/utilities/www-remover";
import prismQueryFilter from "~/content/filters/prism-query-filter";
import storyCardFilter from "~/content/filters/story-card";

import defaults from "~/components/features/fronts/flex-feature/utilities/custom-field-defaults";
import { adjustOverrides } from "~/components/features/fronts/flex-feature/utilities";

// NOTE: Needed for generating jsonapp output
import { getVitalItemData } from "~/components/features/fronts/flex-feature/jsonapp";
import { allTransformsOrder } from "~/shared-components/story-card/_utilities/helpers";
import { groomHeadlineForJsonApp } from "~/shared-components/story-card/_children/Headline.helpers";
import { getArtSlotInfo } from "~/components/features/fronts/flex-feature/utilities/index";
import fetchLayoutFromArtPosition from "~/shared-components/story-card/_children/artPosition";
import {
  getCompoundLabel,
  groomCompoundLabelForJsonApp
} from "~/shared-components/story-card/_children/Label.helpers";
import { getElection } from "~/shared-components/story-card/_children/Election.helpers";
import { isImageUrl } from "~/shared-components/story-card/_children/Image.helpers";
import { getClosestColspan } from "~/shared-components/story-card/_children/LiveGraphic.helpers.js";
import {
  isAudioArticle,
  isPodcast
} from "~/shared-components/story-card/_children/Audio.helpers";
import {
  isVideo,
  isVerticalVideo
} from "~/shared-components/story-card/_children/Video.helpers";

import { getPreset } from "./presets";
import { carouselTypes, appFeedTypes } from "~/proptypes/carousel";

export const supportedTypes = Object.keys(carouselTypes);
export const MAX_ITEMS = 10;
export const emptyContentConfig = { source: "empty" };

export const isAppFeed = (carouselType) => appFeedTypes.includes(carouselType);

export const getContentConfig = (config = {}, carouselType) => {
  // NOTE: live graphic carouselTypes only have hand-entered ids
  if (/^(.+)-live-graphic$/.test(carouselType)) return emptyContentConfig;

  const { contentConfigValues = {} } = config;
  return formatContentConfig(
    Object.keys(contentConfigValues).length
      ? { ...config, filter: prismQueryFilter }
      : emptyContentConfig
  );
};

const getListOfUrls = ({ overrides }) => {
  const { itemUrls = "" } = overrides;
  if (!itemUrls) return [];
  return itemUrls
    .split(/\s+/)
    .map((_) => removeWWW(_))
    .filter((_) => /^\/./.test(_))
    .filter((_, i, a) => a.findIndex((s) => s === _) === i)
    .filter((_, i) => i < MAX_ITEMS);
};

const getListOfPrismPromoContentConfig = ({ overrides }) =>
  getListOfUrls({ overrides }).map((url) =>
    url
      ? formatContentConfig({
          source: "prism-promo",
          query: { content_path: removeWWW(url) },
          filter: storyCardFilter
        })
      : emptyContentConfig
  );

const getListOfIds = ({ overrides, carouselType }) => {
  const { itemUrls = "" } = overrides;
  if (!itemUrls) return [];
  return itemUrls
    .split(/\s+/)
    .filter(
      (_) =>
        !/^elex-live-graphic$/.test(carouselType) ||
        /HomepageCompactResultsTable$/.test(_)
    )
    .filter((_) => !/^http/.test(_))
    .filter((_, i, a) => a.findIndex((s) => s === _) === i)
    .filter((_, i) => i < MAX_ITEMS);
};

const getListOfLiveGraphicContentConfig = ({ overrides, carouselType }) =>
  getListOfIds({ overrides, carouselType }).map((id) =>
    id
      ? formatContentConfig({
          source: carouselType,
          query: { id }
        })
      : emptyContentConfig
  );

export const getListOfContentConfig = (props) =>
  /^(.+)-live-graphic$/.test(props?.carouselType)
    ? getListOfLiveGraphicContentConfig(props)
    : getListOfPrismPromoContentConfig(props);

export const getFeedTrackingObject = (contentConfig, content) => {
  const { contentService, contentConfigValues } = contentConfig;
  let feed = "NA";
  if (contentService && contentConfigValues) {
    const {
      "author-slug": authorSlug,
      id,
      query,
      section,
      tag
    } = contentConfigValues;
    const hed = get(content, "headlines.basic", "");
    switch (contentService) {
      case "prism-author":
        feed = `a_${authorSlug}`;
        break;
      case "prism-query":
        feed = `q_${(query || "").replace("https://prism.query/", "")}`;
        break;
      case "prism-section":
        feed = `s_${section}`;
        break;
      case "recipe-promos-by-tag":
        feed = `rf_${tag || ""}`; // rf is for recipe-finder
        break;
      case "most-read":
        feed = `mr_${section || "site-wide"}`;
        break;
      case "prism-tag":
        feed = `t_${tag}`;
        break;
      case "wsk-collection":
        feed = `co_${id}_${hed}`;
        break;
      default:
    }
  }
  return { feed };
};

export const enhanceWithTracking = (list = [], tracking) =>
  tracking
    ? list.map((item) => {
        if (item) item.carouselTracking = tracking;
        return item;
      })
    : list;

const filterFnsForPromoImage = {
  default: (item) => isImageUrl(item?.fusion_additions?.promo_image?.url),
  bright: () => true, // cuz bright filterFn takes image into account already
  "elex-live-graphic": () => true
};

const filterFns = {
  default: () => true,
  audio: (item) => isAudioArticle(item) || isPodcast(item),
  bright: (item) => !!item?.fusion_additions?.bright?.url,
  "elex-live-graphic": (item) =>
    /HomepageCompactResultsTable$/.test(item?.component),
  immersion: (item) => !!item?.headlines?.basic,
  recipe: (item) => !!item?.fusion_additions?.recipe_info,
  "vertical-video": (item) => isVerticalVideo(item),
  video: (item) => isVideo(item),
  // NOTE: do app-feed types
  ...appFeedTypes.reduce((acc, type) => {
    acc[type] = () => false;
    return acc;
  }, {})
};

/*
 * Add a placeholder image to each item that lacks a promo or bright image
 * when countShow is true and it's not a video carousel. Bright falls back
 * to regular promo image before falling back to the fallback outright.
 *
 * @param {list} items - the items in the carousel
 * @param {string} carouselType - the type of carousel
 * @param {object} overrides - the preset for the carousel type
 *
 * @return {list} - the list of (potentially enhanced) items
 */
const addFallbackPromoImage = ({ items = [], carouselType, overrides }) => {
  const { countShow } = overrides;
  const key = `fusion_additions.${
    /bright/.test(carouselType) ? "bright" : "promo_image"
  }.url`;
  const keyBak = "fusion_additions.promo_image.url";
  const fallback =
    "https://www.washingtonpost.com/pb/resources/img/twp-social-share.png";
  return items.map((item) => {
    let clone;
    if (countShow && !/video/i.test(carouselType)) {
      if (!isImageUrl(get(item, key))) {
        clone = clonedeep(item);
        set(clone, key, get(item, keyBak, fallback));
      }
    }
    return clone || item;
  });
};

/*
 * Make fixes/adjustements as necessary
 */
const fixItems = (props) => {
  let { items } = props;
  items = addFallbackPromoImage({ ...props, items });
  return items;
};

const reconcileItems = ({ items = [], carouselType }) => {
  return (
    [...items]
      // NOTE: carouselType-specific filter
      .filter(filterFns[carouselType] || filterFns.default)
      .filter(
        filterFnsForPromoImage[carouselType] || filterFnsForPromoImage.default
      )
      // NOTE: Dedupe again (if not a live-graphic) cuz a feed and individual items could be dupes
      .filter(
        (_, i, a) =>
          /^(.+)-live-graphic$/.test(carouselType) ||
          a.findIndex((o) => o._id === _._id) === i
      )
      // NOTE: ... and shrink to MAX_ITEMS
      .filter((_, i) => i < MAX_ITEMS)
  );
};

export const finalizeItems = ({
  listOfContent = [],
  contentItems = [],
  ...props
}) => {
  let items = [...listOfContent, ...contentItems];
  items = fixItems({ ...props, items });
  items = reconcileItems({ ...props, items });
  return items;
};

export const getOverrides = ({ customFields, carouselType, outputType }) => {
  // NOTE: All types have these
  const defaultOverrides = {
    ...defaults,
    // NOTE: As a precaution, force these values cuz they shouldn't appear
    // unless the carouselType preset explicitly specifies they should
    labelShow: false,
    blurbHide: true,
    slideshowShow: false,
    audioHide: true,
    liveTickerHide: true,
    relatedLinksHide: true
    // limit: 10
    // offset: 0
  };

  return adjustOverrides(
    {
      ...defaultOverrides,
      ...getPreset(carouselType, outputType),
      ...customFields,
      flexFeatureContentConfig: customFields.carouselContentConfig
    },
    outputType
  );
};

const getLabel = ({ overrides, outputType, isAdmin }) => {
  const { carouselLabel, carouselLabelUrl, carouselLabelIcon } = overrides;
  const compoundLabel = !carouselLabel
    ? undefined
    : getCompoundLabel({
        overrides: {
          labelShow: true,
          labelType: "Kicker",
          label: carouselLabel,
          labelAlignment: "left",
          labelUrl: carouselLabelUrl,
          labelIcon: carouselLabelIcon,
          labelLinkRemove: false,
          labelNameSpace: "carousel"
        },
        isAdmin
      });
  return /jsonapp/.test(outputType)
    ? groomCompoundLabelForJsonApp({ compoundLabel })
    : compoundLabel;
};

const getCta = ({ overrides, outputType, isAdmin }) => {
  const { carouselLabelUrl } = overrides;
  const {
    carouselCtaLabelType,
    carouselCtaLabel,
    carouselCtaLabelUrl = carouselLabelUrl
  } = overrides;

  const compoundLabel = !carouselCtaLabel
    ? undefined
    : getCompoundLabel({
        overrides: {
          labelShow: true,
          labelType: carouselCtaLabelType || "CTA",
          label: carouselCtaLabel,
          labelAlignment: "left",
          labelUrl: carouselCtaLabelUrl,
          labelLinkRemove: false,
          labelNameSpace: "carouselCta"
        },
        isAdmin
      });
  return /jsonapp/.test(outputType)
    ? groomCompoundLabelForJsonApp({ compoundLabel })
    : compoundLabel;
};

export const getComponentData = (props) => {
  return {
    label: getLabel(props),
    cta: getCta(props)
  };
};

const OPINION_RE = /^(opini(o|ó)n)$/i;

// START: helpers for generating jsonapp output
// NOTE: This is only for the itemType of the carousel itself in jsonapp
export const normalizeCarouselItemType = (carouselType) => {
  if (/^audio-playlist/.test(carouselType))
    carouselType = snakecase(carouselType);
  // NOTE: hack for backward compatibility
  if (/^bright$/.test(carouselType)) return "fronts/brights";
  if (/^elex-live-graphic/.test(carouselType)) carouselType = "live-image";
  // NOTE: in jsonapp, there is no difference btw video and vertical-video
  if (/^vertical-video/.test(carouselType)) carouselType = "video";
  return `carousel/${carouselType}`;
};

// NOTE: Pull in more groom functions and use them as necessary
// NOTE: This is only for the itemType of a carousel item in jsonapp
export const normalizeItemType = (carouselType) =>
  // NOTE: in jsonapp, there is no difference btw video and vertical-video
  /^vertical-video/.test(carouselType) ? "video" : carouselType;

// NOTE: This is only for the itemType of a carousel item in jsonapp
export const getCardify = (carouselType) =>
  !/^elex-live-graphic/.test(carouselType);

export const groomLabel = (label, headline) => {
  if (!label) return undefined;

  // NOTE: if label = headline, or both label and headline equal
  // some form of opinion, then delete label
  if (
    label?.label?.text === headline?.prefix ||
    (OPINION_RE.test(headline?.prefix) && OPINION_RE.test(label?.label?.text))
  )
    return undefined;
  return label;
};

export const groomHeadline = (headline, media, carouselType) => {
  if (!headline) return undefined;

  // NOTE: if video carousel and headline is burned in, don't pass headline
  if (/video/.test(carouselType) && media?.isHeadlineBurned) return undefined;

  headline = groomHeadlineForJsonApp({ headline });

  // NOTE: Apps don't need extra headline data
  delete headline.fontStyle;
  delete headline.size;
  delete headline.alignment;

  // NOTE: Apps only want headline.prefix for immersion when it is /Opini(o|ó)n/
  if (/^(immersion)$/.test(carouselType)) {
    if (!OPINION_RE.test(headline?.prefix)) {
      delete headline.prefix;
      delete headline.isOpinionPrefix;
    }
  } else {
    delete headline.prefix;
    delete headline.isOpinionPrefix;
  }
  return headline;
};

export const groomLink = (link, carouselType) => {
  if (!link) return undefined;

  if (/^(audio)$/.test(carouselType)) {
    if (/article/.test(link.type)) {
      link.subtype = "audio";
    }
  }
  return link;
};

export const groomMedia = (media, carouselType) => {
  if (!media) return undefined;

  // NOTE: Apps don't need extra media data for bright
  if (/^(bright)$/.test(carouselType)) {
    delete media.artWidth;
    delete media.aspectRatio;
    delete media.mediaType;
    delete media.artPosition;
  }
  return media;
};

// NOTE: This groom is unusual in that it lifts the rating from recipe_info,
// puts it into the root object, then deletes it from recipe_info. This is cuz
// the carousel and the web handle the rating differently. Argh!
export const groomRecipeInfo = (json) => {
  const rating = json.recipe_info.rating;
  delete json.recipe_info.rating;
  return {
    ...json,
    rating
  };
};

const groomElection = ({ electionData, overrides }) => {
  const election = getElection({ electionData, overrides }) || {};
  const unitsPicked = getClosestColspan(4, election);
  if (unitsPicked) {
    const { media } = election;
    const liveImage = media.liveImage[unitsPicked];
    return {
      mediaType: "live-image",
      url: liveImage?.tabs[0]?.images[0]?.url,
      aspectRatio: liveImage?.tabs[0]?.images[0]?.aspectRatio,
      link: media.link,
      liveImage,
      unitsPicked
    };
  }
  return undefined;
};

const groomJson = (json, electionData, overrides, carouselType) => {
  if (!json) return json;
  delete json.actions;
  delete json.source;

  // START: Groom headline.
  if (json.headline) {
    json.headline = groomHeadline(json.headline, json.media, carouselType);
  }

  // START: Groom label. NOTE: Goes after groomHeadline
  if (json.label) {
    json.label = groomLabel(json.label, json.headline, carouselType);
  }

  // START: Groom link.
  if (json.link) {
    json.link = groomLink(json.link, carouselType);
  }

  // START: Groom media.
  if (json.media) {
    json.media = groomMedia(json.media, carouselType);
  }

  // START: Groom recipe_info.
  // NOTE: It's setting json. See method for details.
  if (json?.recipe_info?.rating) {
    json = groomRecipeInfo(json, carouselType);
  }

  // START: Groom election
  if (electionData) {
    json.media = groomElection({ electionData, overrides });
  }

  return json;
};

export const getJson = ({
  content,
  electionData,
  overrides,
  outputType,
  carouselType,
  metaValue
}) => {
  const artSlot = getArtSlotInfo({ content, overrides, outputType });
  let { layout } = fetchLayoutFromArtPosition(overrides);

  // Critical piece -- this modifies the layout so the components are ordered the way we want.
  layout = allTransformsOrder({ layout, overrides });

  const { json } = getVitalItemData({
    layout,
    content,
    overrides,
    artSlot,
    outputType,
    metaValue
  });

  return groomJson(json, electionData, overrides, carouselType);
};
// END: helpers for generating jsonapp output

// START: tracking/sendToDataLayer
export const sendToDataLayer = (prev, current) => {
  const isRight = current > prev;
  const isLeft = current < prev;
  if (isRight || isLeft) {
    const dir = isRight ? "right" : "left";
    window?.dataLayer?.push({
      event: "site-onpage-click-event",
      category: "onpage",
      action: "onpage-click-event",
      label: `carousel-nav-${dir}`,
      miscellany: `carousel-nav-${dir}`
    });
  }
};
// END: tracking/sendToDataLayer
